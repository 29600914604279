import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  Wrap,
  WrapItem,
  useToast,
} from "@chakra-ui/react";
import { ContainerSmall } from "../../components/ContainerSmall";
import { ButtonNewBook } from "./components/ButtonNewBook";
import { IconBook } from "./components/IconBook";
import { ModalNewBook } from "../../components/ModalNewBook";
import { useState } from "react";
import { useListHomeBooks } from "./hooks/useListHomeBooks";
import { RiCloseCircleLine, RiEditBoxLine } from "react-icons/ri";
import { api } from "../../service/api";
import { AllLoading } from "../../components/allLoading";
import { useNavigate } from "react-router-dom";
import { useStatusMessageBot } from "../biography/chatbot/hooks/useStatusMessageBot";
import { BOOK_PREVIEW } from "../../assets";
import { ModalDelete } from "../../components/modalDelete";
import { ButtonStatusCredits } from "../../components/ButtonStatusCredits";
import { sumQuantityCredits } from "../../utils/utils";

export function Home() {
  const toast = useToast();
  const navigate = useNavigate();

  const { refetch: refetchBot, loading: loadingBot } = useStatusMessageBot();

  const [isOpen, setIsOpen] = useState(false);
  const { dataList, loading, refetch } = useListHomeBooks();
  const [loadingSend, setLoadingSend] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  async function updateStatus(id: string) {
    try {
      setLoadingSend(true);

      await api.put(`biography/update/status/${id}`);
      refetch();
    } catch (error) {
      toast({
        position: "top-right",
        title: "Meu Biógrafo",
        description: "Não foi possível finalizar.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoadingSend(false);
    }
  }

  async function isCompleteChatBot(id: string) {
    const response = await refetchBot(id);

    if (response) {
      navigate(`/biografia/meu-livro/${id}?type=synopsis`);
    } else {
      navigate(`/biografia/chatboot/${id}`);
    }
  }

  return (
    <>
      {(loading || loadingSend) && <AllLoading />}

      <ModalDelete
        is_open={isOpenDelete}
        api_url={`biography/delete/${deleteId}`}
        title="Deletar livro"
        description="Deseja deletar o livro?"
        onClose={() => {
          setIsOpenDelete(false);
          setDeleteId("");
        }}
        onCloseSend={() => {
          setIsOpenDelete(false);
          setDeleteId("");
          refetch();
        }}
      />

      <ModalNewBook
        is_open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onCloseSend={() => {
          setIsOpen(false);
        }}
      />

      <ContainerSmall>
        <Flex flexDirection="column" width="100%">
          <Flex
            w="100%"
            marginTop={["20px", "20px", "50px"]}
            marginBottom="50px"
            flexDirection="column"
          >
            <Flex
              width="100%"
              justifyContent={["center", "center", "space-between"]}
              alignItems="center"
              flexDirection={["column", "column", "row"]}
            >
              <Text color="#181818" fontWeight={600} fontSize="28px">
                Meus livros
              </Text>
              {!loading && dataList.length > 0 && (
                <Box alignSelf="center" mt={["10px", "10px", "0px"]}>
                  <ButtonNewBook
                    title="Criar novo livro"
                    icon={
                      <Icon fontSize="18px" as={IconBook} color="#FFFFFF" />
                    }
                    onPress={() => {
                      setIsOpen(true);
                    }}
                  />
                </Box>
              )}
            </Flex>
            {!loading && dataList.length <= 0 && (
              <>
                <Text
                  maxWidth="777px"
                  marginTop="20px"
                  color="#1E1E1E"
                  fontWeight={400}
                  fontSize="16px"
                  textAlign={["center", "center", "justify"]}
                >
                  Você ainda não iniciou nenhum livro. Clique em criar novo
                  livro para começar.
                </Text>

                <Box
                  marginTop="30px"
                  alignSelf={["center", "center", "flex-start"]}
                >
                  <ButtonNewBook
                    title="Criar novo livro"
                    icon={
                      <Icon fontSize="18px" as={IconBook} color="#181818" />
                    }
                    onPress={() => {
                      setIsOpen(true);
                    }}
                  />
                </Box>
              </>
            )}
          </Flex>
          <Flex mt="25px" margin={["auto", "auto", "inherit"]}>
            <Wrap spacing="30px">
              {!loading &&
                dataList &&
                dataList.map((item, _) => (
                  <WrapItem
                    key={item.id}
                    gap="10px"
                    alignItems="center"
                    flexDirection="column"
                  >
                    {item.order_book ? (
                      <ButtonStatusCredits
                        title="Páginas:"
                        subtitle={sumQuantityCredits(item.order_book)}
                      />
                    ) : (
                      <ButtonStatusCredits title="Páginas:" subtitle={null} />
                    )}

                    <Flex
                      width="216px"
                      height="304px"
                      borderWidth="1px"
                      borderColor="#36A9E1"
                      borderRadius="10px"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      backgroundColor="#36A9E1"
                      position="relative"
                    >
                      {!item.book && (
                        <Image
                          src={BOOK_PREVIEW}
                          alt={"book"}
                          width="216px"
                          height="304px"
                          borderRadius="10px"
                        />
                      )}

                      {item.book && (
                        <Image
                          src={item.book.path}
                          alt={item.book.title}
                          width="216px"
                          height="304px"
                          borderRadius="10px"
                        />
                      )}

                      {!item.book && (
                        <Text
                          position="absolute"
                          color="#000000"
                          fontSize="22px"
                          textAlign="center"
                          fontWeight={500}
                        >
                          {item.title}
                        </Text>
                      )}
                    </Flex>
                    <Flex gap="5px" alignItems="center">
                      <Text>status:</Text>
                      {item.status === "progress" && (
                        <Text color="#FFAB56" fontWeight="bold">
                          em andamento
                        </Text>
                      )}
                      {item.status === "finished" && (
                        <Text color="#42AD4D" fontWeight="bold">
                          finalizado
                        </Text>
                      )}
                    </Flex>
                    <Flex>
                      {item.status === "finished" && (
                        <ButtonNewBook
                          icon={
                            <Icon
                              fontSize="18px"
                              as={IconBook}
                              color="#181818"
                            />
                          }
                          title="Publicar"
                          bgColor="#42AD4D"
                          onPress={() => {
                            navigate(`/livro/meu-livro/${item.id}`);
                          }}
                        />
                      )}
                      {item.status === "progress" && (
                        <ButtonNewBook
                          icon={
                            <Icon
                              fontSize="18px"
                              as={IconBook}
                              color="#181818"
                            />
                          }
                          title="Finalizar"
                          bgColor="#36A9E1"
                          onPress={() => {
                            updateStatus(item.id);
                          }}
                        />
                      )}
                    </Flex>
                    <Flex gap="5px" alignItems="center">
                      <Button
                        isLoading={loadingBot}
                        style={{ color: "#36A9E1", fontWeight: 600 }}
                        variant="link"
                        onClick={() => {
                          isCompleteChatBot(item.id);
                        }}
                        rightIcon={
                          <Icon
                            as={RiEditBoxLine}
                            fontSize="18px"
                            color="#36A9E1"
                          />
                        }
                      >
                        Editar
                      </Button>
                    </Flex>
                    <Flex gap="5px" alignItems="center">
                      <Button
                        isLoading={loadingBot}
                        style={{ color: "#FF5656", fontWeight: 600 }}
                        variant="link"
                        onClick={() => {
                          setDeleteId(item.id);
                          setIsOpenDelete(true);
                        }}
                        rightIcon={
                          <Icon
                            as={RiCloseCircleLine}
                            fontSize="18px"
                            color="#FF5656"
                          />
                        }
                      >
                        Excluir
                      </Button>
                    </Flex>
                  </WrapItem>
                ))}
            </Wrap>
          </Flex>
        </Flex>
      </ContainerSmall>
    </>
  );
}
